<template>
	<Component
		:is="errorPage"
		v-if="errorPage"
		class="l-error"
		v-bind="{ error, ...(content === null ? {} : content) }"
	/>

	<article v-else class="l-error">
		<header>
			<h1 v-text="computedTitle"></h1>
		</header>

		<div v-if="isDevelopment">
			<p>
				Note: this is a placeholder, please add an error page via `yarn
				new` prior to going live
			</p>
		</div>
	</article>
</template>

<script>
import fetchUmbracoData from '@limbo-works/umbraco-client/client';
import { mapGetters, mapState } from 'vuex';

import key from '~/assets/js/page-key';

const ERROR_PAGES_BY_SOLUTION = {
	// Plop: error page - add solution
	main: () =>
		import(
			/* webpackChunkName: "main__errorPage" */ '~/doctypes/main/ErrorPage.vue'
		),
	subsite: () =>
		import(
			/* webpackChunkName: "subsite__errorPage" */ '~/doctypes/subsite/ErrorPage.vue'
		),
};

export default {
	name: 'ErrorLayout',

	key: (route) => key(route),

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	props: {
		error: {
			type: Object,

			default: () => ({
				statusCode: 500,
				message: 'Oops, something went wrong.',
			}),
		},
	},

	data() {
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
		};
	},

	head() {
		return this.content?.meta || {};
	},

	computed: {
		...mapGetters(['solution']),
		...mapState({ content: 'error' }),

		errorPage() {
			return ERROR_PAGES_BY_SOLUTION[this.solution];
		},

		computedTitle() {
			return `${this.error.statusCode} ${this.error.message}`;
		},
	},

	async created() {
		const { site } = this.$store.state;
		const { commit } = this.$store;

		// Fetch pageNotFoundPage data
		const { statusCode } = this.error || {};
		if (statusCode === 404 && site?.pageNotFoundPage?.url) {
			const data = await fetchUmbracoData({
				onResponse(response) {
					commit(
						'Cookies/SET_COOKIE',
						response?.headers?.['set-cookie']
					);
					return response;
				},
				error: () => {}, // We don't care about errors here
				params: {
					cache: false,
					parts: ['content'],
				},
				route: {
					...this.$route,
					path: site.pageNotFoundPage.url,
					fullPath: site.pageNotFoundPage.url,
				},
			});

			if (!data || !data.content) {
				return;
			}

			const { content } = data;
			commit('SET_ERROR_DATA', content);

			commit('SET_TEMPLATE', content.template);
			commit('SET_PAGE_ID', content.id);
		}
	},

	beforeDestroy() {
		this.$store.commit('SET_ERROR_DATA', null);
	},
};
</script>
