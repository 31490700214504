// Last modified: 2022/10/27 15:21:33
const { visualViewport } = window;

if (visualViewport) {
	let pendingUpdate = false;
	const viewportHandler = (e) => {
		if (pendingUpdate) {
			return;
		}
		pendingUpdate = true;

		requestAnimationFrame(() => {
			pendingUpdate = false;

			const { width, height, offsetTop, offsetLeft } = visualViewport;
			const { documentElement } = document;

			documentElement.style.setProperty(
				'--visual-viewport-width',
				`${width}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-height',
				`${height}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-offset-top',
				`${offsetTop}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-offset-left',
				`${offsetLeft}px`
			);
		});
	};

	viewportHandler();
	visualViewport.addEventListener('scroll', viewportHandler);
	visualViewport.addEventListener('resize', viewportHandler);
}
