// Last modified: 2022/08/03 13:31:11
/**
 * Method to extract the page component used for the route if it exists.
 * @param {Route Object} route
 * @returns component object or undefined
 */
export function getAppFromRoute(route) {
	return route?.matched?.[0]?.instances?.default?.$nuxt ?? undefined;
}

/**
 * Method to compare to URL paths.
 * @param {string} a First path.
 * @param {string} b Second path.
 * @param {boolean} [ignoreQueryParams=true]
 * @returns {boolean}
 */
export function comparePaths(a, b, ignoreQueryParams = true) {
	if (a?.length && b?.length) {
		const splitRegex = /([^?]*)\??(.*)/;
		let [, aPath, aQuery] = a.match(splitRegex);
		let [, bPath, bQuery] = b.match(splitRegex);
		aPath = aPath.split('/').filter(Boolean);
		bPath = bPath.split('/').filter(Boolean);

		const aCompare = [aPath, !ignoreQueryParams && aQuery];
		const bCompare = [bPath, !ignoreQueryParams && bQuery];
		return aCompare.join('') === bCompare.join('');
	}

	return false;
}

// And here we export all the helpers as well
export default { getAppFromRoute, comparePaths };
