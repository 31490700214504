import Vue from 'vue';

/*
	Note that global components will be available across
	ALL solutions so only add global components with the
	greatest care.
*/
const components = {
	// component: () => import('~/components/shared/path'),
	BaseButton: () => import('~/components/shared/Base/BaseButton'),
	BaseInputDropdown: () =>
		import('~/components/shared/Base/BaseInputDropdown'),
	BaseInputSearch: () => import('~/components/shared/Base/BaseInputSearch'),
	BaseInputRadioButtons: () =>
		import('~/components/shared/Base/BaseInputRadioButtons'),
	BaseH1: () => import('~/components/shared/Base/BaseH1'),
	BaseH2: () => import('~/components/shared/Base/BaseH2'),
	BaseH3: () => import('~/components/shared/Base/BaseH3'),
	BaseH4: () => import('~/components/shared/Base/BaseH4'),
};

Object.entries(components).forEach(([name, component]) => {
	Vue.component(name, component);
});
