// Last modified: 2023/12/15 10:22:40
import Vue from 'vue';

let isClick = true;
document.addEventListener('click', () => {
	isClick = true;
});
document.addEventListener('keydown', () => {
	isClick = false;
});

const defaultOptions = {
	contextElm: document.body,
	zIndex: 9999,
	keyboardFocusOnly: false,

	class: 'js-focus-overlay',
	activeClass: 'js-focus-overlay--is-active',
	activeVisibleClass: 'js-focus-overlay--is-active-visible',
	partialClass: 'js-focus-overlay__partial',
	targetClass: 'js-focus-overlay__target',

	redirectAttr: 'data-focus',
	ignoreAttr: 'data-focus-ignore',
};

let updateTimeout = null;
const focusOverlay = Vue.observable({
	elm: null,
	targetElm: null,
	previousTargetElm: null,
	options: { ...defaultOptions },
	resizeObserver: null,

	// Starting / stopping the focus overlay
	isRunning: false,

	start(options) {
		// If already running, do a restart
		if (this.isRunning) {
			this.stop();
		}

		// Merge options
		if (options) {
			Object.assign(this.options, options);
		}

		if (this.options.contextElm) {
			// Prepare update loop
			this.onChangeOfFocus();
			this.options.contextElm.addEventListener(
				'focusout',
				this.onChangeOfFocus
			);
			this.options.contextElm.addEventListener(
				'focusin',
				this.onChangeOfFocus
			);
		}
		this.isRunning = true;
	},

	stop() {
		this.isRunning = false;
		this.setTarget(null);

		// Stop the update loop
		if (this.options.contextElm) {
			this.options.contextElm.removeEventListener(
				'focusout',
				this.onChangeOfFocus
			);
			this.options.contextElm.removeEventListener(
				'focusin',
				this.onChangeOfFocus
			);
		}
	},

	// Methods
	setTarget(targetElm) {
		if (targetElm) {
			// Ignore the target
			if (targetElm.hasAttribute(this.options.ignoreAttr)) {
				this.setTarget(null);
				return;
			}

			// Redirect the target
			if (targetElm.hasAttribute(this.options.redirectAttr)) {
				const query = targetElm.getAttribute(this.options.redirectAttr);
				this.setTarget(document.querySelector(query));
				return;
			}
		}

		// Assign and remove classes
		if (this.options.targetClass) {
			removeClassEverywhere(
				this.options.contextElm,
				this.options.targetClass
			);
			if (targetElm) {
				targetElm.classList.add(this.options.targetClass);
			}
		}

		// Update the actual value
		if (targetElm !== this.targetElm) {
			this.previousTargetElm = this.targetElm;
			this.targetElm = targetElm;

			this.moveFocusOverlay(targetElm, document.activeElement);
		}

		// Set targetElm inner rects
		this.setInnerRects();
	},

	moveFocusOverlay(targetElm, activeElement) {
		window.removeEventListener('scroll', this.onWindowChange, true);
		window.removeEventListener('resize', this.onWindowChange, true);

		this.resizeObserver =
			this.resizeObserver ||
			new window.ResizeObserver(this.onResizeObservation);
		this.resizeObserver.disconnect();

		window.clearTimeout(updateTimeout);
		updateTimeout = window.setTimeout(() => {
			if (targetElm) {
				// Add event listeners for repositioning
				window.addEventListener('scroll', this.onWindowChange, {
					capture: true,
					passive: true,
				});
				window.addEventListener('resize', this.onWindowChange, {
					capture: true,
					passive: true,
				});
				targetElm && this.resizeObserver.observe(targetElm);

				// Create element if it doesn't exist
				let newlyCreated = false;
				if (!this.elm) {
					this.elm = document.createElement('div');
					this.options.class &&
						this.elm.classList.add(this.options.class);
					this.options.contextElm.appendChild(this.elm);
					newlyCreated = true;
				}

				// Add active class
				this.options.activeClass &&
					this.elm.classList.add(this.options.activeClass);

				// Add active visible class
				if (
					!isClick &&
					activeElement &&
					activeElement.matches(':focus-visible')
				) {
					this.options.activeVisibleClass &&
						this.elm.classList.add(this.options.activeVisibleClass);
				} else {
					this.options.activeVisibleClass &&
						this.elm.classList.remove(
							this.options.activeVisibleClass
						);
				}

				// Set styles of focus overlay
				const { width, height, top, left } =
					targetElm.getBoundingClientRect();
				if (typeof this.options.zIndex !== 'undefined') {
					this.elm.style.zIndex = this.options.zIndex;
				}
				this.elm.style.position = 'fixed';
				this.elm.style.pointerEvents = 'none';
				this.elm.style.left = 0;
				this.elm.style.top = 0;
				this.elm.style.width = `${width}px`;
				this.elm.style.height = `${height}px`;
				this.elm.style.transform = `translate(${left}px, ${top}px)`;

				// Match the border radius of the target element
				const {
					borderBottomLeftRadius,
					borderBottomRightRadius,
					borderTopLeftRadius,
					borderTopRightRadius,
				} = window.getComputedStyle(this.targetElm);
				this.elm.style.borderBottomLeftRadius = borderBottomLeftRadius;
				this.elm.style.borderBottomRightRadius =
					borderBottomRightRadius;
				this.elm.style.borderTopLeftRadius = borderTopLeftRadius;
				this.elm.style.borderTopRightRadius = borderTopRightRadius;

				// Suppress transitions on first run
				if (newlyCreated) {
					this.elm.style.transition = 'unset';
					window.setTimeout(() => {
						if (this.elm) {
							this.elm.style.transition = null;
						}
					}, 30);
				} else {
					this.elm.style.transition = null;
				}
			} else {
				this.elm?.remove?.();
				this.elm = null;
			}
		}, 0);
	},

	setInnerRects() {
		window.setTimeout(() => {
			const { elm, targetElm } = this;
			if (elm && targetElm) {
				const { top, left } = targetElm.getBoundingClientRect();
				const rects = [...targetElm.getClientRects()];

				// Add extra inner divs
				while (rects.length > elm.childElementCount) {
					const div = document.createElement('div');
					this.options.partialClass &&
						div.classList.add(this.options.partialClass);
					elm.appendChild(div);
				}

				// Remove surplus inner divs
				while (rects.length < elm.childElementCount) {
					const div = elm.lastChild;
					elm.removeChild(div);
				}

				// Set sizes of inner rects
				rects.forEach((rect, index) => {
					const { width, height } = rect;
					let { top: rectTop, left: rectLeft } = rect;
					rectTop -= top;
					rectLeft -= left;

					const partial = this.elm.childNodes[index];

					partial.style.position = 'absolute';
					partial.style.left = `${rectLeft}px`;
					partial.style.top = `${rectTop}px`;
					partial.style.width = `${width}px`;
					partial.style.height = `${height}px`;
				});
			}
		}, 0);
	},

	// Event Listeners
	onChangeOfFocus() {
		let targetElm = null;
		if (
			document.activeElement &&
			document.activeElement !== focusOverlay.options.contextElm &&
			focusOverlay.options.contextElm?.contains?.(document.activeElement)
		) {
			targetElm = document.activeElement;
		}

		if (focusOverlay.options.keyboardFocusOnly && isClick) {
			targetElm = null;
		}

		focusOverlay.setTarget(targetElm);
	},

	onWindowChange() {
		if (focusOverlay.targetElm && focusOverlay.elm) {
			const { top, left } =
				focusOverlay.targetElm.getBoundingClientRect();
			focusOverlay.elm.style.transform = `translate(${left}px, ${top}px)`;

			// Suppress transition
			focusOverlay.elm.style.transition = 'unset';
			window.setTimeout(() => {
				if (focusOverlay.elm) {
					focusOverlay.elm.style.transition = null;
				}
			}, 30);

			// Set targetElm inner rects
			focusOverlay.setInnerRects();
		}
	},

	onResizeObservation(e) {
		if (focusOverlay.targetElm && focusOverlay.elm) {
			const {
				width: oldWidth,
				height: oldHeight,
				transform: oldTransform,
			} = focusOverlay.elm.style;
			const { top, left, width, height } =
				focusOverlay.targetElm.getBoundingClientRect();
			focusOverlay.elm.style.width = `${width}px`;
			focusOverlay.elm.style.height = `${height}px`;
			focusOverlay.elm.style.transform = `translate(${left}px, ${top}px)`;

			// Suppress transition
			if (
				oldWidth !== focusOverlay.elm.style.width ||
				oldHeight !== focusOverlay.elm.style.height ||
				oldTransform !== focusOverlay.elm.style.transform
			) {
				focusOverlay.elm.style.transition = 'unset';
				window.setTimeout(() => {
					if (focusOverlay.elm) {
						focusOverlay.elm.style.transition = null;
					}
				}, 30);
			}

			// Set targetElm inner rects
			focusOverlay.setInnerRects();
		}
	},
});

// Removes all instances of a given class from an element and its children
function removeClassEverywhere(contextElm, className) {
	if (contextElm && className) {
		[...contextElm.querySelectorAll(`.${className}`)].forEach((elm) => {
			elm.classList.remove(className);
		});
	}
}

export default focusOverlay;
