// Last modified: 2022/08/03 16:06:14
import { getAppFromRoute } from '~/citi-baseline/assets/js/utilities/helpers';

export default (to, from) => {
	const fromComponent = getAppFromRoute(from);
	const toComponent = getAppFromRoute(to);
	const solution = (fromComponent || toComponent)?.$store?.getters?.solution;
	if (solution) {
		return `t-page-${solution}`;
	}

	return 't-page';
};
