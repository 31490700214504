import { camelCase, upperFirst } from 'lodash';

const DOCTYPES_BY_SOLUTION_AND_TEMPLATE = {
	// Plop: doctype - add solution
	subsite: {
		// Plop: doctype - add subsite doctype
		FrontPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeFrontPage" */ '~/doctypes/subsite/FrontPageCustom.vue'
			),
		OverviewListSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeOverviewPage" */ '~/doctypes/subsite/OverviewPageCustom.vue'
			),
		ContentPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeContentPage" */ '~/doctypes/subsite/ContentPageCustom.vue'
			),
		NewsListSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeNewsList" */ '~/doctypes/subsite/NewsListCustom.vue'
			),
		NyhedSubsite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeNewsPage" */ '~/doctypes/subsite/NewsPageCustom.vue'
			),
		NewsPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeNewsPage" */ '~/doctypes/subsite/NewsPageCustom.vue'
			),
		SearchPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeSearchPage" */ '~/doctypes/subsite/SearchPageCustom.vue'
			),
	},
	main: {
		// Plop: doctype - add main doctype
		CalendarForm: () =>
			import(
				/* webpackChunkName: "main__doctypeCalendarForm" */ '~/doctypes/main/CalendarFormCustom.vue'
			),
		AssociationCalendarPage: () =>
			import(
				/* webpackChunkName: "main__doctypeAssociationCalendarPage" */ '~/doctypes/main/AssociationCalendarPageCustom'
			),
		CalendarPage: () =>
			import(
				/* webpackChunkName: "main__doctypeCalendarPage" */ '~/doctypes/main/CalendarPageCustom'
			),
		AssociationCalendarList: () =>
			import(
				/* webpackChunkName: "main__doctypeAssociationCalendarList" */ '~/doctypes/main/AssociationCalendarListCustom'
			),
		CalendarList: () =>
			import(
				/* webpackChunkName: "main__doctypeCalendarList" */ '~/doctypes/main/CalendarListCustom'
			),
		VerdictPage: () =>
			import(
				/* webpackChunkName: "main__doctypeHearingPage" */ '~/doctypes/main/HearingPageCustom.vue'
			),
		HearingPage: () =>
			import(
				/* webpackChunkName: "main__doctypeHearingPage" */ '~/doctypes/main/HearingPageCustom.vue'
			),
		HearingList: () =>
			import(
				/* webpackChunkName: "main__doctypeHearingList" */ '~/doctypes/main/HearingListCustom.vue'
			),
		SearchPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSearchPage" */ '~/doctypes/main/SearchPageCustom.vue'
			),
		JobList: () =>
			import(
				/* webpackChunkName: "main__doctypeJobList" */ '~/doctypes/main/JobListCustom.vue'
			),
		NewsList: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsList" */ '~/doctypes/main/NewsListCustom.vue'
			),
		SelfserviceList: () =>
			import(
				/* webpackChunkName: "main__doctypeSelfserviceList" */ '~/doctypes/main/SelfserviceListCustom.vue'
			),
		CouncilMemberList: () =>
			import(
				/* webpackChunkName: "main__doctypeCouncilMemberList" */ '~/doctypes/main/CouncilMemberListCustom.vue'
			),
		JobPage: () =>
			import(
				/* webpackChunkName: "main__doctypeJobPage" */ '~/doctypes/main/JobPageCustom.vue'
			),
		FrontPage: () =>
			import(
				/* webpackChunkName: "main__doctypeFrontPage" */ '~/doctypes/main/FrontPageCustom.vue'
			),
		SubjectPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSubjectPage" */ '~/doctypes/main/SubjectPageCustom.vue'
			),
		SubjectList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewPage" */ '~/doctypes/main/OverviewPageCustom.vue'
			),
		SelfservicePage: () =>
			import(
				/* webpackChunkName: "main__doctypeSelfservicePage" */ '~/doctypes/main/SelfservicePageCustom.vue'
			),
		NewsPage: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsPage" */ '~/doctypes/main/NewsPageCustom.vue'
			),
		OverviewList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewPage" */ '~/doctypes/main/OverviewPageCustom.vue'
			),
		ContentPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPageCustom.vue'
			),
		SubscriptionConfirmationPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/SubscriptionPageCustom.vue'
			),
	},
};

export const getDoctypeFromSolutionAndTemplate = (solution, template) =>
	upperFirst(camelCase(`${solution}${template}`));

// format that allows local registration via `components`
export default Object.entries(DOCTYPES_BY_SOLUTION_AND_TEMPLATE).reduce(
	(acc, [solution, doctypesByTemplate]) => ({
		...acc,

		...Object.entries(doctypesByTemplate).reduce(
			(acc, [template, doctype]) => ({
				...acc,

				[getDoctypeFromSolutionAndTemplate(solution, template)]:
					doctype,
			}),
			{}
		),
	}),
	{}
);
