export const state = () => ({
	groups: [],
	infoboxes: [],
	searchId: '',
});

export const mutations = {
	SET_DATA(state, data) {
		data.groups && (state.groups = data.groups);
		data.infoboxes && (state.infoboxes = data.infoboxes);
	},

	SET_SEARCH_ID(state, id) {
		state.searchId = id;
	},
};

export const getters = {
	longreadGroups: ({ groups }) => groups.filter((group) => group.total),
};

export const actions = {
	SET_SEARCH_ID({ commit }) {
		const searchid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0;
				const v = c === 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);

		commit('SET_SEARCH_ID', searchid);
	},
};
