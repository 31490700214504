export const state = () => ({
	groups: [],
	infoboxes: [],
});

export const mutations = {
	SET_DATA(state, data) {
		data.groups && (state.groups = data.groups);
		data.infoboxes && (state.infoboxes = data.infoboxes);
	},
};

export const getters = {
	longreadGroups: ({ groups }) => groups.filter((group) => group.total),
};
