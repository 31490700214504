import fetchUmbracoData from '@limbo-works/umbraco-client/client';

import { getDoctypeFromSolutionAndTemplate } from '~/assets/js/doctypes';
import { getLayoutFromSolutionAndTemplate } from '~/assets/js/layouts';

const DEFAULT_SOLUTION_NAME = 'main';

export const state = () => ({
	current: {
		page: null,
		doctype: null,
		guid: null,
	},
	pageId: null,
	previousPageId: null,
	currentPath: null,
	previousPath: null,
	template: null,
	error: null,
	site: null,
	solution: null,
	colorTheme: 'blue',
	editorLink: null,
	isReducedMotion: false,
});

export const actions = {
	async nuxtServerInit(
		_,
		{ req, $config, error, redirect, route, store: { commit, state } }
	) {
		const data = await fetchUmbracoData({
			endpointUrl: process.env.REDIS_URL
				? process.env.GET_DATA_ENDPOINT
				: undefined,
			onResponse(response) {
				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				return response;
			},
			error,
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: 'site',
				appHost: $config.APP_HOST || req.headers.host,
				headers: {
					cookie: req?.headers?.cookie || '',
				},
			},
			redirect,
			route,
		});

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { site = {} } = data;
		commit('SET_SITE_DATA', site);
		commit('SET_TEMPLATE', site.pageTemplateAlias);
		commit('SET_COLOR_THEME', site.theme?.label || state.colorTheme);
	},
};

export const mutations = {
	_SET_CURRENT_DATA(state, { content }) {
		const {
			solution,
			mappings: { doctypes },
		} = state;

		const solutionMap = doctypes[solution];
		const mappedDoctype = solutionMap?.[content?.template];

		if (!mappedDoctype && this.$isDev) {
			this.$error(
				`[store/index.js] '_SET_CURRENT_DATA'. Template name "${content?.template}" wasn't mapped on solution "${state.solution}"`
			);

			return null;
		}

		this.$ctxSet(state.current, 'page', content);
		state.current.doctype = mappedDoctype;

		return true;
	},

	SET_TEMPLATE(state, template) {
		state.template = template;
	},

	SET_PAGE_ID(state, id) {
		state.previousPageId = state.pageId;
		state.pageId = id;
	},

	SET_ERROR_DATA(state, error) {
		state.error = error;
	},

	SET_SITE_DATA(state, site) {
		if (!site) {
			return;
		}

		state.solution = site?.alias ?? 'main';
		state.site = site;
	},

	SET_CURRENT_PATH(state, path) {
		state.previousPath = state.currentPath;
		state.currentPath = path;
	},

	SET_COLOR_THEME(state, colorTheme) {
		state.colorTheme = colorTheme || 'blue';
	},

	SET_EDITOR_LINK(state, editorLink) {
		state.editorLink = editorLink;
	},

	SET_REDUCED_MOTION(state, isReducedMotion) {
		state.isReducedMotion = isReducedMotion;
	},
};

export const getters = {
	solution: ({ solution }) => solution ?? DEFAULT_SOLUTION_NAME,

	layout: ({ template }, { solution }) =>
		getLayoutFromSolutionAndTemplate(solution, template),
	doctype: ({ template }, { solution }) =>
		getDoctypeFromSolutionAndTemplate(solution, template),

	colorTheme: ({ colorTheme }) => colorTheme,
};
